import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { UserService } from '../../data';

export const IsRegisterableEAGuard: CanActivateFn = (
	routeSnapshot: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
	// Iben: Inject the user service and fetch the roles
	const userService: UserService = inject(UserService);
	const router: Router = inject(Router);
	const i18nService: NgxI18nService = inject(NgxI18nService);

	// Iben: Return whether the user has a correct role for this route
	return userService.loading$.pipe(
		filter((loading: boolean) => !loading),
		switchMap(() =>
			userService.isRegisterableEA().pipe(
				map((isRegisterableEA: boolean): boolean | UrlTree => {
					// Denis: If the user has the requested role, early return with
					// true to move the routing along to the next step.
					if (isRegisterableEA) {
						return true;
					}

					// Denis: check if the redirect URL is presented as an array.
					// This is to avoid the spread operator breaking the guard
					// when non-Array values are passed.
					const redirectUrl = Array.isArray(routeSnapshot.data.redirect)
						? [...routeSnapshot.data.redirect]
						: [routeSnapshot.data.redirect];

					// Iben: Route to the redirect if the user doesn't have the rule
					return router.createUrlTree([i18nService.currentLanguage, ...redirectUrl]);
				})
			)
		)
	);
};
